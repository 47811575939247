<script setup>
const nuxtApp = useNuxtApp()

const { data, status, error } = await useLazyAsyncData('featuredproducts', () => $fetch('/api/product/featured'), {
    transform: (data) => {
        return {
            featuredProducts: data.products?.edges.map((edge) => edge.node),
            fetchedAt: new Date(Date.now()),
        }
    },
    getCachedData(key) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

        if (!data) {
            return
        }

        const expirationDate = new Date(data.fetchedAt)
        expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
        // console.log('expirationDate = ', expirationDate.toISOString())
        const isExpired = expirationDate.getTime() < Date.now()
        // console.log('now = ', new Date(Date.now()).toISOString())
        if (isExpired) {
            // console.log('expired fetch again')
            return
        }

        return data
    },
})

watchEffect(() => {
    if (error.value) {
        throw createError({
            statusCode: 500,
            message: error.value,
            fatal: false,
        })
    }
})
</script>

<template>
    <v-row id="productsfeatured" class="justify-center">
        <v-col v-if="status === 'pending' || status === 'idle'" cols="10"><app-loader /></v-col>
        <v-col v-else cols="10" sm="10">
            <v-row>
                <v-col cols="12" class="mt-16 mb-4">
                    <app-section-header
                        :id="`featuredproducts`"
                        :title="`Featured Products`"
                        :subtitle="`Featured for their awesomeness`"
                    />
                </v-col>
            </v-row>
            <v-row class="justify-center">
                <v-col v-for="product in data.featuredProducts" :key="product.id" cols="12" sm="6" lg="3">
                    <product-card :product="product" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="mb-16 mt-10 d-flex justify-center">
                    <v-theme-provider theme="sTLightTheme">
                        <app-button
                            :to="`/shop`"
                            :btn-text="`View All Products`"
                            :append-icon="`mdi-chevron-right`"
                            size="x-large"
                            aria-label="View all products"
                        />
                    </v-theme-provider>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped></style>
